import React from "react"
// import { Link } from "gatsby"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import Blockquote from "@components/blockquote"

import {
  FormWrapper,
  RequestMoreInfo
} from "@components/Forms"

const GuitarLuthierRandyKing = () => {

  return (
    <PageLayout>
      <SEO 
          title="Guitar Luthier in Georgia | Your Custom Build | King Electric Guitars" 
          ogDescription="What's the difference between a guitar luthier, guitar builder, someone who builds parts-casters? I'll share my thoughts on it and tell you how I got started building custom guitars."
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Electric Guitar Luthier - Randy King</h1>
            <p>
              To be honest, I'm not crazy about calling myself a luthier at this point. To me, a luthier is someone with decades of experience. They build acoustic guitars, classical guitars, archtop guitars, and hollow-body electric guitars. That's not what I do. But, a lot of folks will call themselves "guitar builders" when all they do is assemble guitars from parts catalogs. There's nothing wrong with that, but that's not what I do either.
            </p>
            <h2>Electric Guitars, Built From Scratch</h2>
            <p>
              I'm not building parts-casters. I'm building everything from scratch. I design my own guitar shapes. Yes, classic designs influence me. T-style, S-style, LP-style, and classic offset <a href="/guitar-body/">guitar bodies</a> are often what players want. But I take my designs in a different direction you can't find anywhere else. So people can't say, "he's putting together parts that he buys online". No, it's all by hand. All from scratch. All from rough wood that I select from the lumber yard.
            </p>
            <p>
              Again, not that there's anything wrong with putting together guitars from parts. It's a great way to start. But, you shouldn't call yourself a guitar builder, much less a luthier, when you're assembling parts-casters. If you say you are a guitar maker, you should be making instruments with your own hands - from scratch. If you're a company that says "we finish guitar kits", that's fine. But, if you're saying that you're a guitar builder, you should be a complete guitar builder.
            </p>
            <p>
              So, I'm a guitar builder. Building custom guitars to my customers' particular needs and specifications.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/randy-king-electric-guitar-luthier.jpg"
              alt="Electric Guitar Luthier in Griffin Georgia, Randy King"
            />
            <Blockquote>
              I'm building everything from scratch. I design my own guitar shapes. Yes, classic designs influence me. T-style, S-style, LP-style, and classic offset guitar bodies are often what players want. But I take my designs in a different direction you can't find anywhere else.
            </Blockquote>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/red-rocket-deluxe.jpg"
              alt="Randy King in Red Rocket Deluxe"
            />
          </div>
        }
        textRight={
          <div>
            <h2>How I Got Interested In Guitar Building</h2>
            <p>
              In my early teens, I came up the idea to build a guitar when I saw Steve Vai in Guitar Player Magazine holding this crazy thing. It was all flames. "Wait a minute,", I thought, "my dad can cut that out. And it can't be that hard to <a href="/custom-guitar-necks/">make a neck</a>." And so I always had this idea that he and I were gonna build a guitar. Unfortunately, he passed away before we could. 
          </p>
          <p>
            Later in my twenties, I went to a luthier and I said, "Hey, man, I wanna learn guitar making, cuz it seems really cool." He told me you don't make any money and discouraged me as much as he could. So, I kind of forgot about it.
            </p>
            <p>
              But the idea always stuck with me. Meanwhile, I was learning to do all my own guitar setups and repairs. I was in a rock band called Red Rocket Deluxe for about 15 years. Plus I was in a bunch of punk bands. I was always ripping guitars apart. I'm kind of a big dude. I was always snatching the guitar strap buttons out. I was always messing up something. So I figured out how to do repairs.
            </p>
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>Guitar Building and Repair Apprenticeship</h3>
              <p>
                Guitar building is a hands-on learning process. Most guitar luthiers learn and polish their skill set through an apprenticeship. I begged for an apprenticeship at a place called <a href="https://www.instagram.com/brownsguitarmill/" target="_blank" rel="noreferrer">Brown's Guitar Mill in Newnan, GA</a>. And John Brown said, yes. So, I drove my giant '96 Ford van - spending about a hundred dollars a day on gas - not getting paid for the first couple of years. But, I learned a lot. Eventually he gave me a paying role and I worked there for several more years.
              </p>
              <p>
                The first day I showed up at the shop, I said "Dude, I wanna build guitars."
              </p>
              <p>
                He replied, "Yeah, everybody does. But, you should learn how to repair guitars first."
              </p>
              <p>
                And I said, "No. I wanna build guitars!" And he said the most profound thing. "Anybody can build a guitar-shaped object."
              </p>
              <p>
                And then a guy brought in a guitar-shaped object that he had built. One look and I thought "No way. I can't let myself do something like this."
              </p>
              <p>
                So I spent time learning how to repair guitars and learn what made them good or bad. What's wrong with certain designs? What causes guitars to do certain things? Finish repairs, headstock repairs, neck repairs, <a href="/guitar-setup/">guitar setup</a>, and so on. I learned it all at that shop. 
              </p>
              <FormWrapper
                modal
                buttonProps={{
                  content: "Reach Out. Let's Talk!",
                  size: "tiny",
                  icon: "chat",
                }}
              >
                <RequestMoreInfo />
              </FormWrapper>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/randy-king-at-browns-guitar-mill.jpg"
                alt="Electric Guitar Luthier Apprienticeship"
              />
              <Blockquote>
                I said, "No. I wanna build guitars!" And he said the most profound thing. "Anybody can build a guitar-shaped object."
              </Blockquote>
              <p>
                
              </p>
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/king-electric-guitars-logo-black.png"
              alt="King Electric Guitars Logo"
            />
          </div>
        }
        textRight={
          <div>
            <h3>King Electric Guitars is Born</h3>
            <p>
Then COVID hit. I had recently bought a house in Griffin, GA and a large guitar-making shop was part of the deal. It needed a lot of work, but I had nothing else to do. I couldn't go to Brown's Guitar Mill due to social distancing. So, I built my first guitar here. And it wasn't merely a "guitar-shaped object". It turned out to be an actual playable guitar. 
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <p>
              Soon, I was building my first customer's guitar, which went to France. Then I built two or three other ones. Since then, I've been off and running. I've designed new body styles influenced by traditional guitars, but with my own twists. More body contouring. Slightly offset bodies. Slightly over-sized or under-sized bodies depending on the player. I've done custom bridge setups for low tunings.               
            </p>
            <p>
              I spent a lot of time coming up with a stylish looking headstock. It can make or break the look of a custom guitars. 
            </p>
            <p>
              I have created my own T-style body called the <a href="/t-style-guitar-taurus/">Taurus</a>. It's a bit larger and slightly offset. I have a LP-style body now called <a href="/lp-style-guitar-futura/">Futura</a>, and I will have guitars coming soon for that platform. I'm also working on a new offset body style, called The Space Dolphin. It's inspired by classic 1960's era Japanese guitars. 
            </p>
            <p>
              I'm experimenting with many <a href="/guitar-finishing/">different kinds of finishes</a>. From fabric tops to custom paint colors to all natural wood finishes.
            </p>
            <p>
              There's always some new idea brewing. And I'm happy to make your ideas come to life.<br /><br />
            </p>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default GuitarLuthierRandyKing
